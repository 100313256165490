@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;  /* fallback for old browsers */
  color: whitesmoke; 
}



.gradient { 
  width: 720px;
  min-height: 400px;
}

.gradient1 { 
  width: 200%;
  min-height: 200%;
  
}

.radial-gradient-position {
  background-image: radial-gradient(circle at bottom right, #78005c -120%, #00000000 50%);
}
.radial-gradient-position1 {
  background-image: radial-gradient(circle at bottom left, #78005c -120%, #00000000 50%);
}
.radial-gradient-position2 {
  background-image: radial-gradient(circle at right, #78005c -100%, #00000000 30%);
}

.radial-gradient-position3 {
  background-image: radial-gradient(circle , #2c0457 -40%, #00000000 65%);
}

.img1 {
  animation: jump-shaking 2s infinite;
}

.img2 {
  animation: tilt-shaking 2.5s infinite;
}

.img3 {
  animation: tilt-n-move-shaking  2.2s infinite;
}

.img4 {
  animation: vertical-shaking 2.8s infinite;
}

.img5 {
  animation: horizontal-shaking 2.3s infinite;
}

.img6 {
  animation: skew-x-shake 2.3s infinite;
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

@keyframes tilt-n-move-shaking {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(5px, 5px) rotate(5deg); }
  50% { transform: translate(0, 0) rotate(0eg); }
  75% { transform: translate(-5px, 5px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

@keyframes vertical-shaking {
  0% { transform: translateY(0) }
  25% { transform: translateY(5px) }
  50% { transform: translateY(-5px) }
  75% { transform: translateY(5px) }
  100% { transform: translateY(0) }
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}

@keyframes jump-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateY(-9px) }
  35% { transform: translateY(-9px) rotate(17deg) }
  55% { transform: translateY(-9px) rotate(-17deg) }
  65% { transform: translateY(-9px) rotate(17deg) }
  75% { transform: translateY(-9px) rotate(-17deg) }
  100% { transform: translateY(0) rotate(0) }
}

@keyframes skew-x-shake {
  0% { transform: skewX(-15deg); }
  5% { transform: skewX(15deg); }
  10% { transform: skewX(-15deg); }
  15% { transform: skewX(15deg); }
  20% { transform: skewX(0deg); }
  100% { transform: skewX(0deg); }  
}

@keyframes skew-y-shake {
  0% { transform: skewY(-15deg); }
  5% { transform: skewY(15deg); }
  10% { transform: skewY(-15deg); }
  15% { transform: skewY(15deg); }
  20% { transform: skewY(0deg); }
  100% { transform: skewY(0deg); }  
}


